import React, { useEffect } from "react"
import {Link} from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import logo from "../../assets/images/laufid_logo.svg"
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";

const LandingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Landing | Laufið";
    document.body.className = "authentication-bg";
  });

  return (
      <div className="landing-page" style={{marginTop: '10%'}}>
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={6}>
              <Card className="auth_card">
                <CardBody>

                  <LanguageDropdown/>

                  <div className="auth_logo"><img src={logo} alt="" height="100"/></div>

                  <div class="copy">
                    {t('onboarding_page.challenge_msg')}
                  </div>

                  <Link className="btn btn-success w-100 waves-effect waves-light font-size-14 text-center" to="/registration">
                    {t('onboarding_page.register_here')}
                  </Link>

                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <span>© {new Date().getFullYear()} Laufið</span>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
  )
};

export default LandingPage;