import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";

import i18n from "../../../i18n";
import './index.scss';

import GreenStepMunicipality from "../components/customGreenSteps/greenStepMunicipality";
import GreenStepDefault from "../components/customGreenSteps/greenStepDefault";
import GreenStep62 from "../components/customGreenSteps/greenStep62";
import GreenStep142 from "../components/customGreenSteps/greenStep142";
import GreenStep144 from "../components/customGreenSteps/greenStep144";
import GreenStep154 from "../components/customGreenSteps/greenStep154";
import GreenStep155 from "../components/customGreenSteps/greenStep155";
import GreenStep157 from "../components/customGreenSteps/greenStep157";
import GreenStep168 from "../components/customGreenSteps/greenStep168";
import GreenStep173 from "../components/customGreenSteps/greenStep173";
import GreenStep177 from "../components/customGreenSteps/greenStep177";
import GreenStep178 from "../components/customGreenSteps/greenStep178";
import GreenStep179 from "../components/customGreenSteps/greenStep179";
import GreenStep191 from "../components/customGreenSteps/greenStep191";
import GreenStep196 from "../components/customGreenSteps/greenStep196";
import GreenStep197 from "../components/customGreenSteps/greenStep197";
import GreenStep201 from "../components/customGreenSteps/greenStep201";
import GreenStep202 from "../components/customGreenSteps/greenStep202";
import GreenStep203 from "../components/customGreenSteps/greenStep203";
import GreenStep204 from "../components/customGreenSteps/greenStep204";
import GreenStep205 from "../components/customGreenSteps/greenStep205";
import GreenStep206 from "../components/customGreenSteps/greenStep206";
import GreenStep207 from "../components/customGreenSteps/greenStep207";
import GreenStep210 from "../components/customGreenSteps/greenStep210";
import GreenStep211 from "../components/customGreenSteps/greenStep211";
import GreenStep212 from "../components/customGreenSteps/greenStep212";
import GreenStep213 from "../components/customGreenSteps/greenStep213";
import GreenStep214 from "../components/customGreenSteps/greenStep214";
import GreenStep215 from "../components/customGreenSteps/greenStep215";
import GreenStep216 from "../components/customGreenSteps/greenStep216";
import GreenStep217 from "../components/customGreenSteps/greenStep217";
import GreenStep218 from "../components/customGreenSteps/greenStep218";
import GreenStep219 from "../components/customGreenSteps/greenStep219";
import GreenStep220 from "../components/customGreenSteps/greenStep220";

const Index = (props) => {
  const navigate = useNavigate();
  const {bigCategory} = props;
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const {state} = useLocation();
  const cardRef = useRef([]);
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const isInstitution = firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null;

  const {municipalities} = useSelector( state => state.municipality );
  const [myMunicipality, setMyMunicipality] = useState([]);


  useEffect(() => {
    if(municipalities && isInstitution) {
      let municipality = municipalities.results.find(f => f.id === firstCompany.municipality_id);
      if(municipality) {
        setMyMunicipality(municipality);
      }
    }
  }, [municipalities]);

  useEffect(() => {
    if(bigCategory && state && state.addOwnCarbon) {
      let cid = state.addOwnCarbon;
      setTimeout(function(){
        cardRef?.current[cid]?.scrollIntoView();
        navigate('/adgerdir', {});
      }, 500);
      // window.scrollTo({ top: cardRef.current[state.addOwnCarbon]?.getBoundingClientRect().top, behavior: 'smooth'})
    }
  },[state, bigCategory])


  const customGreenSteps = [
    62, 142, 144, 154, 155, 157, 168, 173, 177, 178, 179, 191, 196, 197, 201,
    202, 203, 204, 205, 206, 207, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220
  ];

  return (
    <div className="row greenStepBigCategory">

      {bigCategory && bigCategory.map(category =>
        <React.Fragment key={"bigCategoryFragment"+category.id}>
          {
            category.new_green_steps_not_completed.map((item, categoryIndex) => {
              let desc = item["description_" + currentLang] ? item["description_" + currentLang] : item.description;
              let descHasLink = desc && desc.includes('http');
              let companyGreenStep = item.new_company_green_steps.length>0 ? item.new_company_green_steps[0] : false;
              let isIrrelevant = companyGreenStep && companyGreenStep.hasOwnProperty('irrelevant') && companyGreenStep.irrelevant === true;
              return (
                <div className="col col-12" key={"renderBigCategory_greenSteps_"+categoryIndex} ref={el => cardRef.current[item.id] = el}>
                  <Card>
                    <CardBody>

                      {!customGreenSteps.includes(item.id) && !isInstitution &&
                        <GreenStepDefault
                          item={item}
                          descHasLink={descHasLink}
                          desc={desc}
                          companyGreenStep={companyGreenStep}
                          companyGreenStepIsIrrelevant={isIrrelevant}
                        />
                      }

                      {!customGreenSteps.includes(item.id) && isInstitution && item.type === "municipality" &&
                        <GreenStepMunicipality
                          item={item}
                          descHasLink={descHasLink}
                          desc={desc}
                          myMunicipality={myMunicipality}
                        />
                      }

                      {item.id === 62 && <GreenStep62 item={item} companyGreenStep={companyGreenStep} /> }
                      {item.id === 142 && <GreenStep142 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 144 && <GreenStep144 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 154 && <GreenStep154 item={item} descHasLink={descHasLink} desc={desc}/>}
                      {item.id === 155 && <GreenStep155 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} />}
                      {item.id === 157 && <GreenStep157 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} />}
                      {item.id === 168 && <GreenStep168 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 173 && <GreenStep173 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 177 && <GreenStep177 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 178 && <GreenStep178 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 179 && <GreenStep179 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 191 && <GreenStep191 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 196 && <GreenStep196 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 197 && <GreenStep197 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 201 && <GreenStep201 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 202 && <GreenStep202 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 203 && <GreenStep203 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 204 && <GreenStep204 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 205 && <GreenStep205 item={item}/> }
                      {item.id === 206 && <GreenStep206 item={item} isInstitution={isInstitution}/> }
                      {item.id === 207 && <GreenStep207 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 210 && <GreenStep210 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 211 && <GreenStep211 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 212 && <GreenStep212 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 213 && <GreenStep213 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 214 && <GreenStep214 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 215 && <GreenStep215 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 216 && <GreenStep216 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 217 && <GreenStep217 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 218 && <GreenStep218 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 219 && <GreenStep219 item={item} companyGreenStep={companyGreenStep} /> }
                      {item.id === 220 && <GreenStep220 item={item} companyGreenStep={companyGreenStep} /> }

                      {item.quotation &&
                        <div className="quotation categoryTextColor">
                          {item.quotation}
                        </div>
                      }
                    </CardBody>
                  </Card>
                </div>
              )
            })
          }
        </React.Fragment>
      )}

    </div>
  )
};

export default Index