import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  notifications: [],
  notificationsMenu: [],
  loadingNotificationsMenu: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function notificationReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_NOTIFICATIONS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
    return {
      ...state,
      notifications: action.data,
      loading: false
    };
    case actionTypes.GET_NOTIFICATIONS_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_NOTIFICATIONS_READ_START:
      return  {
        ...state
      };
    case actionTypes.GET_NOTIFICATIONS_READ_SUCCESS:
      const id = action.data.results.id;
      let newStateN = Object.assign([], state.notifications);
      const notifIndex = newStateN.results.findIndex(f => f.id === id);
      newStateN.results[notifIndex].read_at = action.data.results.read_at;
      return {
        ...state,
        notifications: newStateN
      };
    case actionTypes.GET_NOTIFICATIONS_READ_FAIL:
      return  {
        ...state
      };

    case actionTypes.GET_NOTIFICATIONS_MENU_START:
      return  {
        ...state,
        loadingNotificationsMenu: true
      };
    case actionTypes.GET_NOTIFICATIONS_MENU_SUCCESS:
    return {
      ...state,
      notificationsMenu: action.data,
      loadingNotificationsMenu: false
    };
    case actionTypes.GET_NOTIFICATIONS_MENU_FAIL:
      return  {
        ...state,
        loadingNotificationsMenu: false
      };

    case actionTypes.GET_NOTIFICATIONS_MENU_READ_START:
      return  {
        ...state,
        loadingNotificationsMenu: true
      };
    case actionTypes.GET_NOTIFICATIONS_MENU_READ_SUCCESS:
      let notifDataMenu = Object.assign([], state.notificationsMenu.results);

      const indexC = notifDataMenu.findIndex(item => item.id === action.data.results.id);
      if(indexC >= 0) {
        notifDataMenu[indexC].read_at = action.data.results.read_at;
      }
      state.notificationsMenu.results = notifDataMenu;
      return {
        ...state,
        loadingNotificationsMenu: false
      };
    case actionTypes.GET_NOTIFICATIONS_MENU_READ_FAIL:
      return  {
        ...state,
        loadingNotificationsMenu: false
      };

    default:
      return state;
  }
}
