import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const loginUser = (data, navigate) => async dispatch => {
  dispatch({type: actionTypes.LOGIN_START});
  try {
    data.email = data.email.toLowerCase();

    const res = await axios({
      url: '/auth/email_login',
      method: 'POST',
      data: {...data}
    });

    if(res.data.message === 'Successfully login.' && res.data.code === 200) {
      let company_users = res.data.results.user.company_users.filter(value => !!value?.company);
      const data = {
        "access_token": res.data.results.access_token,
        "token_type": res.data.results.token_type,
        "user": res.data.results.user,
        "roles": res.data.results.roles,
        "permission": res.data.results.permission.map((value) => (value.name)),
        "companyId": company_users[0].company_id,
        "companyIds": company_users.map((value) => value.company_id),
        "companies": company_users.map((value) => value.company ),
      };
      dispatch({type: actionTypes.LOGIN_SUCCESS, data: data});
      localStorage.setItem('p_user', JSON.stringify(data));
      if(data.companies[0].onboarded === false) {
        navigate("/adgerdir");
      } else {
        navigate("/dashboard");
      }

    }

  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
    dispatch({type: actionTypes.LOGIN_FAIL, data: error.response ? error.response.data.message : 'Something went wrong'});
  }
};


export const loginByPhone = (details, navigate) => async dispatch => {
  dispatch({type: actionTypes.LOGIN_PHONE_START});

  try {
    const res = await axios({
      url: '/auth/phone_login',
      method: 'POST',
      data: {...details}
    });
    if(res.data.message === 'SMS sent' && res.data.code === 200) {
      const data = {
        "msg": res.data.message,
        "phone": details.phone,
      };
      dispatch({type: actionTypes.LOGIN_PHONE_SUCCESS, data: data});
    }
    if(res.data.message === 'Successfully login.' && res.data.code === 200) {
      let company_users = res.data.results.user.company_users.filter(value => !!value?.company);
      const data = {
        "access_token": res.data.results.access_token,
        "token_type": res.data.results.token_type,
        "user": res.data.results.user,
        "roles": res.data.results.roles,
        "permission": res.data.results.permission.map((value) => (value.name)),
        "companyId": company_users[0].company_id,
        "companyIds": company_users.map((value) => value.company_id),
        "companies": company_users.map((value) => value.company ),
      };
      dispatch({type: actionTypes.LOGIN_SUCCESS, data: data});
      localStorage.setItem('p_user', JSON.stringify(data));
      if(data.companies[0].onboarded === false) {
        navigate("/adgerdir");
      } else {
        navigate("/dashboard");
      }
    }

  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.LOGIN_PHONE_FAIL, data: errorMessage});
  }
};

export const loginSms = (data, navigate) => async dispatch => {
  dispatch({type: actionTypes.LOGIN_START});

  try {
    const res = await axios({
      url: '/auth/sms_verify_login',
      method: 'POST',
      data: {data}
    });

    if(res.data.message === 'Successfully login.' && res.data.code === 200) {
      let company_users = res.data.results.user.company_users.filter(value => !!value?.company);

      const data = {
        "access_token": res.data.results.access_token,
        "token_type": res.data.results.token_type,
        "user": res.data.results.user,
        "roles": res.data.results.roles,
        "permission": res.data.results.permission.map((value) => (value.name)),
        "companyId": company_users[0].company_id,
        "companyIds": company_users.map((value) => value.company_id),
        "companies": company_users.map((value) => value.company ),
      };
      dispatch({type: actionTypes.LOGIN_SUCCESS, data: data});
      localStorage.setItem('p_user', JSON.stringify(data));
      if(data.companies[0].onboarded === false) {
        navigate("/adgerdir");
      } else {
        navigate("/dashboard");
      }
    }
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
    dispatch({type: actionTypes.LOGIN_FAIL, data: error.response.data.message});
  }
};

export const resetPassword = (data) => async dispatch => {
  dispatch({type: actionTypes.RESET_PASSWORD_START});
  try {
    const res = await axios({
      url: '/user/reset_password',
      method: 'POST',
      data: {...data}
    });

  dispatch({type: actionTypes.RESET_PASSWORD_SUCCESS, data: res.data.message});
  dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: res.data.message, style:'success'});

  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'The provided credentials are incorrect.', style:'error'});
    dispatch({type: actionTypes.RESET_PASSWORD_FAIL, data: error.response ? error.response.data.message : 'Something went wrong'});
  }
};

export const updatePassword = (data, navigate) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_PASSWORD_START});
  try {
    const res = await axios({
      url: '/user/update_password',
      method: 'POST',
      data: {...data}
    });

    if(res.data.message === 'Password succesfully updated!' && res.data.code === 200) {
      dispatch({type: actionTypes.UPDATE_PASSWORD_SUCCESS, data: res.data.message});
      dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: res.data.message, style:'success'});
      navigate('/login');
    }

  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'The provided credentials are incorrect.', style:'error'});
    dispatch({type: actionTypes.UPDATE_PASSWORD_FAIL, data: error.response ? error.response.data.message : 'Something went wrong'});
  }
};


export const verifyEmail = ({email, time, hash}) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_PASSWORD_START});
  try {
    dispatch({type: actionTypes.VERIFY_EMAIL_START});
    await axios({
      url: '/user/verify_email',
      method: 'POST',
      data: {email, time, hash},
    });
    dispatch({type: actionTypes.VERIFY_EMAIL_SUCCESS});
  } catch (error) {
    dispatch({type: actionTypes.VERIFY_EMAIL_FAIL, data: error.response ? error.response.data.message : 'Something went wrong'});
  }
};

export const loginByPhoneFail = () => async dispatch => {
  dispatch({type: actionTypes.LOGIN_SMS_FAIL});
};


export const logout = (navigate) => async dispatch => {
  dispatch({type: actionTypes.LOGOUT_START});

  try {
    const res = await axios({
      url: '/auth/logout',
      method: 'POST',
      headers: {'Ability': 'admin'}
    });
    if(res.data.message === 'Successfully logged out.') {
      localStorage.clear();
      navigate('/login');
    }
    dispatch({type: actionTypes.LOGOUT_SUCCESS, data: res.data.results});
  } catch (error) {
    localStorage.clear();
    navigate('/login');
    dispatch({type: actionTypes.LOGOUT_FAIL, data: 'error'});
  }

};
