import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';

import profileHeader from "../../assets/images/empty_cover_photo.png";
import companyLogo from "../../assets/images/empty_logo.png";

import Users from "./users";
import Companies from "./companies";
import Stories from "./stories";

import ChangeLogo from "./components/changeLogo";
import ChangeBanner from "./components/changeBanner";
import "./index.scss"
import {getCompanyCategories} from "../../store/actions/companyCategory";

const UserProfile = () => {
  const dispatch = useDispatch();
  const {updatedStore} = useSelector(state => state.company);
  const { t } = useTranslation();

  const storageData = JSON.parse(localStorage.getItem('p_user'));
  const [company, setCompany] = useState(storageData && storageData.companies.length>0 ? storageData.companies[0] : false);
  const [activeTab, setActiveTab] = useState('user');
  const [alert, setAlert] = useState('');
  const [coverPhoto, setCoverPhoto] = useState('');
  const [logo, setLogo] = useState('');

  useEffect(() => {
    dispatch(getCompanyCategories())
  }, []);

  useEffect(() => {
    if(updatedStore) {
      setCompany(storageData.companies[0]);
      setCoverPhoto(storageData.companies[0].cover_photo ? storageData.companies[0].cover_photo : profileHeader);
      setLogo(storageData.companies[0].logo ? storageData.companies[0].logo : companyLogo);
    }
  }, [updatedStore]);

  useEffect(() => {
    if(coverPhoto.length === 0) {
      setCoverPhoto(company && company.cover_photo ? company.cover_photo : profileHeader);
      setLogo(company.logo ? company.logo : companyLogo);
    }
  }, [company]);


  const onChangeLogo = () => {
    setAlert(
      <ChangeLogo
        currentLogo={logo}
        companyId={company.id}
        onCancel={() => setAlert('')}
      />
    );
  };

  const onChangeBanner = () => {
    setAlert(
      <ChangeBanner
        currentImg={coverPhoto}
        companyId={company.id}
        onCancel={() => setAlert('')}
      />
    );
  };

  return (
    <div className="page-content userProfile">
      {alert}
      <div className="row header">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <div className="banner">
            <div className="bannerImage" style={{backgroundImage: "url(" + coverPhoto + ")"}}/>
          </div>

          <div className="logo">
            <div className="logoImg">
              <div className="logoImage" style={{backgroundImage: "url(" + logo + ")"}}/>
            </div>
            <div className="changeLogo hover-effect" onClick={() => onChangeLogo() }>{t('settings_page.change_logo')}</div>
          </div>

          <div className="copy">
            <div className="companyName">{company && company.name}</div>
            <div className="companyKennitala"> - {t('settings_page.kennitala')} {company && company.kennitala}</div>
          </div>

          <div className="changeHeaderImage hover-effect" onClick={() => onChangeBanner()}>{t('settings_page.change_cover_photo')}</div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <div className="cardTabs">
            <div className={`tab ${activeTab === 'user' && 'active'}`} onClick={() => setActiveTab('user')}>
              {t('settings_page.users')}
            </div>
            <div className={`tab ${activeTab === 'company' && 'active'}`} onClick={() => setActiveTab('company')}>
              {t('settings_page.company_profile')}
            </div>
            <div className={`tab ${activeTab === 'stories' && 'active'}`} onClick={() => setActiveTab('stories')}>
              {t('settings_page.company_stories')}
            </div>
            {/*{company.municipality_id === null &&*/}
            {/*  <div className={`tab ${activeTab === 'subscription' && 'active'}`} onClick={() => setActiveTab('subscription')}>*/}
            {/*    {t('settings_page.subscription')}*/}
            {/*  </div>*/}
            {/*}*/}
          </div>
        </div>
      </div>

      {activeTab === 'user' && <Users/>}
      {activeTab === 'company' && <Companies/>}
      {activeTab === 'stories' && <Stories/>}
      {/*{activeTab === 'subscription' && <Subscription/>}*/}
    </div>
  )
};
export default UserProfile;