import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Table,  Card, CardBody, Button} from "reactstrap"
import {Done, RemoveDone} from '@mui/icons-material';

import {deleteStory, getStories, updateCompanyStoryStatus} from "../../store/actions/story";
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"
import faviconNotification from "../../assets/images/favicon-not-32x32.png";
import {notificationMenuMarkAsRead} from "../../store/actions/notification";

const formateDate = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return dd + '-' + mm + '-' + yyyy;
};

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const {stories, loading} = useSelector( state => state.story );
  const {notificationsMenu, loadingNotificationsMenu} = useSelector(state => state.notification);

  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canApprove = permission.includes('admin_company_environmental_approve');
  const canDelete = permission.includes('admin_company_environmental_delete');
  const [notificationsIds, setNotificationsIds] = useState([]);

  useEffect(() => {
    dispatch(getStories());
  }, []);

  useEffect(() => {
    if(notificationsMenu && notificationsMenu.results) {
      let filter = notificationsMenu.results.filter(f => f.read_at === null);
      if(filter.length>0) {
        let ids = filter.map(item => item.notifiable_id);
        setNotificationsIds(ids)
      }
    }
  }, [notificationsMenu]);

  const handleChangeStatus = (value, status) => {
    dispatch(updateCompanyStoryStatus(value.id, status))
  };

  const handleReadNotification = (id) => {
    let notfItem = notificationsMenu.results.find(f => f.notifiable_id === id);
    if(notfItem) {
      dispatch(notificationMenuMarkAsRead(notfItem.id));
    }
  }

  if (loading || loadingNotificationsMenu) return (<LoaderSpinner/>);

  const tableData = !stories.results ? '' :
    stories.results.map((value, index) => {
      return (
        <tr
          className={notificationsIds.includes(value.id) ? `table-warning` : ''}
          key={index}
          onClick={() => handleReadNotification(value.id)}
        >
          <td>{index+1}</td>
          <td><img className="img-thumbnail rounded category_img" src={value.company?.logo} alt=""/> {value.company?.name} </td>
          <td>
            <React.Fragment>
              <Button className={`statusBtn ${value.approved}`} size="sm">
                {t('company_story_page.'+value.approved)}
              </Button> &nbsp;
            </React.Fragment>
          </td>
          <td>{value.link}</td>
          <td>{formateDate(value.updated_at)}</td>
          <td className="actionsColumn">
            {value.approved !== 'approved' && <Button key={value.id+'approve'} className="approveBtn" color="success" size="sm" onClick={() => handleChangeStatus(value, 'approved')}>{t('company_story_page.approved')}</Button>}
            <Button key={value.id+'unApprove'} color="danger" size="sm" onClick={() => handleChangeStatus(value, 'rejected')}>{t('company_story_page.rejected')}</Button>
            {canDelete && value.company && <DeleteButton action={() => dispatch(deleteStory(value.id))} msg={t('delete')}/>}
          </td>
        </tr>
      )
    });

  return (
    <div className="page-content companyStoriesList">
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('company_environmental_page.company')}</th>
                  <th>{t('company_environmental_page.status')}</th>
                  <th>Video</th>
                  <th>{t('company_environmental_page.date')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
