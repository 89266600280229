import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Close, Favorite, FavoriteBorder, PlayCircleOutline} from "@mui/icons-material";
import DatePicker from "react-datepicker";
import is from "date-fns/locale/is";
import {createUpdateActionPlan} from "../../../store/actions/actionPlan";
import {useDispatch} from "react-redux";
import new_green_step_1 from "../../../assets/images/new_green_step_1.svg"
import new_green_step_2 from "../../../assets/images/new_green_step_2.svg"
import new_green_step_3 from "../../../assets/images/new_green_step_3.svg"
import updated_green_step_1 from "../../../assets/images/updated_green_step_1.svg"
import updated_green_step_2 from "../../../assets/images/updated_green_step_2.svg"
import updated_green_step_3 from "../../../assets/images/updated_green_step_3.svg"
import {Modal, UncontrolledTooltip} from "reactstrap";
import i18n from "../../../i18n";
import ReactPlayer from "react-player";

const ScoreAndFavorite = (props) => {
  const {item, companyGreenStep} = props;
  const currentLang = i18n.language || 'is';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [alert2, setAlert2] = useState('');

  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [favoriteItem, setFavoriteItem] = useState(null);


  const onChangeFavoriteDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const saveFavorite = () => {
    let newData = {
      "company_id": companyId,
      "action": favoriteItem.title,
      "type": 'green_step',
      "priority": null,
      "start_date": startDate,
      "end_date": endDate,
      "plan_goal_id": null,
      "subgoal": "",
      "status": null,
      "user_ids": null,
      "comment": null,
      "new_green_step_id": favoriteItem.id
    };
    dispatch(createUpdateActionPlan(false, newData, item.main_category-1));

    setFavoriteItem(null);
  };

  const newGreenSteps = [208,209,210,212,213,214,219]
  const updatedGreenSteps = [62,142,144,168,173,177,178,179,191,196,197,201,202,204]

  const showVideo = (item) => {
    let video = item["video_" + currentLang] ? item["video_" + currentLang] : item.video
    setAlert2(
      <Modal isOpen={true} centered={true} size="lg" toggle={() => setAlert2('')} className="greenStepVideoModal">
        <div className="modal-body">
          <ReactPlayer
            className="videoFrame"
            url={video}
            playing={true}
            controls
          />
          <div onClick={() => setAlert2('')} className="videoClose" data-dismiss="modal">
            <Close/>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="scoreAndFavoriteContainer">
      {alert2}
      {item.video &&
        <div className="showVideo" onClick={() => showVideo(item)}>
          <PlayCircleOutline/>
        </div>
      }
      {
        newGreenSteps.includes(item.id) &&
        <React.Fragment>
          <img
            src={
              props && item.main_category ?
                (item.main_category === 3 ? new_green_step_3 : item.main_category === 2 ? new_green_step_2 : new_green_step_1)
                : new_green_step_1
            }
            className={`statusIcon ${currentLang}`}
            alt="New Green Step"
            key={item.id + "_new_green_step"}
            id={'tooltip_' + item.id + "_new_green_step"}
          />
          <UncontrolledTooltip placement="bottom" target={'tooltip_' + item.id + "_new_green_step"}>
            {t('pluses_page.new_action')}
          </UncontrolledTooltip>
        </React.Fragment>
      }
      {
        updatedGreenSteps.includes(item.id) &&
        <React.Fragment>
          <img
            src={
              props && item.main_category ?
                (item.main_category === 3 ? updated_green_step_3 : item.main_category === 2 ? updated_green_step_2 : updated_green_step_1)
                : updated_green_step_1
            }
            className={`statusIcon ${currentLang}`}
            alt="Updated Green Step"
            key={item.id + "_updated_green_step"}
            id={'tooltip_' + item.id + "_updated_green_step"}
          />
          <UncontrolledTooltip placement="bottom" target={'tooltip_' + item.id + "_updated_green_step"}>
            {t('pluses_page.updated_action')}
          </UncontrolledTooltip>
        </React.Fragment>
      }

      <p className="score categoryTextColor">
        {item.slider === true && companyGreenStep && companyGreenStep.slider_score ? companyGreenStep.slider_score + '/10 ' : item.score} {t('pluses_page.points')}
      </p>
      <div className="favorite">
        {item.company_plan_actions && item.company_plan_actions.length > 0 ?
          <Favorite className="categoryTextColor"
                    onClick={() => props.deleteActionPlan(item.company_plan_actions[0].id)}/>
          :
          <FavoriteBorder className="categoryTextColor" onClick={() => setFavoriteItem(item)}/>
        }
      </div>

      {favoriteItem && favoriteItem.id === item.id &&
        <div className="favoriteCalendar">
          <div className="header categoryTextColor">{t('pluses_page.scheduleAction')}</div>
          <DatePicker
            selected={startDate}
            onChange={onChangeFavoriteDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            startDate={startDate}
            endDate={endDate}
            dropdownMode="select"
            selectsRange
            selectsDisabledDaysInRange
            inline
            locale={is}
          />
          <div className="footer">
            <div className="cancel" onClick={() => setFavoriteItem(null)}>{t('cancel')}</div>
            <div className="save" onClick={() => saveFavorite()}>{t('save')}</div>
          </div>
        </div>
      }
    </div>
  )
};

export default ScoreAndFavorite