import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AddCircle, Cancel} from '@mui/icons-material';
import {Input} from "reactstrap";
import Select from "react-select";
import {useTranslation} from "react-i18next";

import {customDropdownCO2Styles} from "../../helpers/dropdown";
import {AddCommas, RemoveNonNumeric, RemoveNonNumericForSave} from "./components/helpers";
import {createCompanyCarbonEmission,} from "../../store/actions/carbon";
import i18n from "../../i18n";

const emptyValues = {
  'year': '',
  'yearError': false,

  'car': '',
  'gas': '',
  'diesel': '',
  'fuelService': '',

  'waterService': '',
  'water': '',

  'electService': '',
  'electric': '',

  'wasteService': '',
  'wastes': [
    {'type': '', 'treatment': '', 'value': ''}
  ],
  'wastesError': false
};

const CreateCarbonMainPage = (props) => {
  const { t } = useTranslation();
  const {mainStep, onChangeMainStep} = props;
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const {services, emissions, companyEmissions} = useSelector( state => state.carbon );
  const currentLang = i18n.language || 'is';

  const [state, setState] = useState(emptyValues);
  const [scope3WasteOptions, setScope3WasteOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([
    {label: 2020, value: '2020'},
    {label: 2021, value: '2021'},
    {label: 2022, value: '2022'},
    {label: 2023, value: '2023'},
    {label: 2024, value: '2024'}
  ]);

  useEffect(() => {
    if(companyEmissions && companyEmissions.results) {
      let itemKeys = Object.keys(companyEmissions.results);

      const myArrayFiltered = yearOptions.filter((el) => {
        return itemKeys.every((f) => {
          return f !== el.value;
        });
      });

      setYearOptions(myArrayFiltered);
    }
  }, [companyEmissions]);

  useEffect(() => {
    if(props.continueYear !== '') {
      let step = '1.0';
      let states = {...state};
      states['year'] = props.continueYear;
      let data  = companyEmissions.results[props.continueYear];
      let wasteOptions = getEmissionsByYear(props.continueYear);

      if(data[1]) {
        states['car'] = 'yes'
        states['gas'] = data[1]['gas'] && data[1]['gas'].value ? data[1]['gas'].value : "";
        states['diesel'] = data[1]['diesel'] && data[1]['diesel'].value ? data[1]['diesel'].value : "";
        let serviceId = data[1]['gas'] ? data[1]['gas'].service_provider_id : data[1]['diesel'] ? data[1]['diesel'].service_provider_id : 0;
        let service = services.results.find(f => f.id === serviceId);
        states['fuelService'] = service ? {label: service.name, value: service.name, id: service.id} : "";

        if(serviceId) { step = '1.1' }
        if(states['gas'] !== "" || states['diesel'] !== "") { step = '1.2' }
      }

      if(data[2]) {
        states['water'] = data[2]['water'] && data[2]['water'].value ? data[2]['water'].value : "";
        let serviceIdWater = data[2]['water'] ? data[2]['water'].service_provider_id : 0;
        let serviceWater = services.results.find(f => f.id === serviceIdWater);
        states['waterService'] = serviceWater ? {label: serviceWater.name, value: serviceWater.name, id: serviceWater.id} : "";

        states['electric'] = data[2]['electricity'] && data[2]['electricity'].value ? data[2]['electricity'].value : "";
        let serviceIdElec = data[2]['electricity'] ? data[2]['electricity'].service_provider_id : 0;
        let serviceElec = services.results.find(f => f.id === serviceIdElec);
        states['electService'] = serviceElec ? {label: serviceElec.name, value: serviceElec.name, id: serviceElec.id} : "";

        if(serviceIdWater) {step = '2.0'}
        if(states['water'] !== "") {step = '2.1'}
        if(serviceIdElec) {step = '2.2'}
        if(states['electric'] !== "") {step = '2.3'}
      }

      if(data[3]) {
        let data3 = Object.values(data[3]).filter(f => typeof f !== 'string');
        let serviceWaste = services.results.find(f => f.id === data3[0].service_provider_id);
        states['wasteService'] = serviceWaste ? {label: serviceWaste.name, value: serviceWaste.name, id: serviceWaste.id} : "";
        states.wastes = [];
        data3.forEach(item => {
          let waste = wasteOptions.find(f => f.id === item.carbon_emission_id);
          if(waste) {
            states.wastes.push({
              'type': waste,
              'treatment': waste.treatments.find(f => f.id === item.carbon_emission_id),
              'value': item.value
            })
          }
        });
        step = '3.0'
      }

      if(!data[3]) {step = '3.0'}
      if(!data[2]) {step = '2.0'}

      setState(states);
      onChangeMainStep(step);
    }
  },[props.continueYear]);

  const footerMsg = [
    {'step': '0',  'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text1')},
    {'step': '1.0', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text2')},
    {'step': '1.1', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text3')},
    {'step': '1.2', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text4')},
    {'step': '2.0', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text3')},
    {'step': '2.1', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text5')},
    {'step': '2.2', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text3')},
    {'step': '2.3', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text6')},
    {'step': '3.0', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text3')},
    {'step': '3.1', 'title': t('carbon_page.why_ask'), 'copy': t('carbon_page.emission_text7')}
  ];
  
  const nextBtnMsg = {
    '0': t('carbon_page.next'),
    '1.0': t('carbon_page.next') + ' (1/3)', '1.1': t('carbon_page.next') + ' (2/3)', '1.2': t('carbon_page.next') +  ' (' + t('carbon_page.scope_small') + ' 2)',
    '2.0': t('carbon_page.next') + ' (1/4)', '2.1': t('carbon_page.next') + ' (2/4)', '2.2': t('carbon_page.next') + ' (3/4)', '2.3': t('carbon_page.next') + ' (' + t('carbon_page.scope_small') + ' 3)',
    '3.0': t('carbon_page.next') + ' (1/2)', '3.1': t('carbon_page.get_results')
  };

  const saveCompanyCarbon = (emission, providerId, value, btn = '') => {
    dispatch(createCompanyCarbonEmission({
      "company_id": companyId,
      "carbon_emission_id": emission.id,
      "service_provider_id": providerId,
      "value": value,
      "result": value === null ? null : value * emission.value,
      "currentStep": mainStep,
      "type": btn
    }));
  };

  const getEmissionsByYear = (yearParam = '') => {
    if(emissions && emissions.results) {
      let year = '';
      let ems = [];

      if(yearParam.length>0) { year = yearParam }
      if(state && state.year) { year = state.year }

      emissions.results.forEach(item => {
        if(item.scope === 3 && item.year === year) {
          if(ems.findIndex(f => f.value === item.type) === -1) {
            let findTreatments = emissions.results.filter(f => f.scope === 3 && f.year === year && f.type === item.type);
            ems.push({
              label: item.type,
              label_is: item.type,
              label_en: item.type_en,
              value: item.type,
              id: item.id,
              treatments: findTreatments.map(item => { return  {
                label: item.treatment,
                label_en: item.treatment_en,
                label_is: item.treatment,
                value: item.value,
                id: item.id}})
            })
          }
        }
      });
      setScope3WasteOptions(ems);
      return ems;
    }
  };

  const nextMainStep = (btn = '') => {
    let step = parseFloat(mainStep)+0.1;
    step = step.toFixed(1);

    if(mainStep === '0') {
      if(state.year === '') {
        step = '0';
        setState({...state, yearError: true});
      } else {
        step = '1.0';
        getEmissionsByYear();
      }
    }

    if(mainStep === '1.0' && state.car === 'no') {
      step = '2.0';
    }

    if(mainStep === '1.1' && btn === 'save' && state.fuelService !== "") {
      let emissionGas = emissions.results.find(f => f.scope === 1 && f.year === state.year && f.type === 'gas');
      saveCompanyCarbon(emissionGas, state.fuelService.id, null, btn);

      let emissionDiesel = emissions.results.find(f => f.scope === 1 && f.year === state.year && f.type === 'diesel');
      saveCompanyCarbon(emissionDiesel, state.fuelService.id,null, btn);
    }

    if(mainStep === '1.2') {
      if(state.fuelService) {
        if(state.gas.length>0) {
          let emission = emissions.results.find(f => f.scope === 1 && f.year === state.year && f.type === 'gas');
          emission && saveCompanyCarbon(emission, state.fuelService.id, RemoveNonNumericForSave(state.gas), btn);
        }
        if(state.diesel.length>0) {
          let emission = emissions.results.find(f => f.scope === 1 && f.year === state.year && f.type === 'diesel');
          emission && saveCompanyCarbon(emission, state.fuelService.id, RemoveNonNumericForSave(state.diesel), btn);
        }
      }
      step = '2.0';
    }

    if(mainStep === '2.0' && btn === 'save' && state.waterService !== "") {
      let emission = emissions.results.find(f => f.scope === 2 && f.year === state.year && f.type === 'water');
      saveCompanyCarbon(emission, state.waterService.id, null, btn);
    }

    if(mainStep === '2.1') {
      if(state.waterService && state.water.length>0) {
        let emission = emissions.results.find(f => f.scope === 2 && f.year === state.year && f.type === 'water');
        emission && saveCompanyCarbon(emission, state.waterService.id, RemoveNonNumericForSave(state.water));
      }
    }

    if(mainStep === '2.2' && btn === 'save' && state.electService !== "") {
      let emission = emissions.results.find(f => f.scope === 2 && f.year === state.year && f.type === 'electricity');
      saveCompanyCarbon(emission, state.electService.id, null, btn);
    }

    if(mainStep === '2.3') {
      if(state.electService && state.electric.length>0) {
        let emission = emissions.results.find(f => f.scope === 2 && f.year === state.year && f.type === 'electricity');
        emission && saveCompanyCarbon(emission, state.electService.id, RemoveNonNumericForSave(state.electric), btn);
      }
      step = '3.0';
      getEmissionsByYear();
    }

    if(mainStep === '3.0') {
      if(btn === 'save' && state.wasteService !== "") {
        let emission = emissions.results.find(f => f.scope === 3 && f.year === state.year && f.type === 'Lífrænn úrgangur' && f.treatment === 'Endurvinnsla/endurnýting');
        saveCompanyCarbon(emission, state.wasteService.id, null, btn);
      }
      getEmissionsByYear();
    }

    if(mainStep === '3.1') {
      let states = {...state};
      let notEmpty = true;
      step = '3.1';

      states.wastes.forEach((item, wIndex) => {
        if(item.type === '' || item.treatment === '' || item.value.length === 0) {
          notEmpty = false;
        }
      });

      if(notEmpty) {
        if(state.wasteService) {
          let states = {...state};
          states.wastes.forEach((item, wIndex) => {
            if(item.type && item.treatment && item.value.length>0) {
              saveCompanyCarbon(item.treatment, states.wasteService.id, RemoveNonNumericForSave(item.value), btn)
            }
          });

          setTimeout(function(){
            window.location.reload();
          }, 1500);

        } else {
          window.location.reload();
        }
      } else {
        states['wastesError'] = true;
        setState(states);
      }
    }

    if(btn === '') {
      onChangeMainStep(step);
    }
  };

  const prevMainStep = () => {
    let step = 0;
    if(mainStep === '1.0') {
      step = '0';
    } else if(mainStep === '2.0') {
      if(state.car === 'no') {
        step = '1.0';
      } else {
        step = '1.2';
      }
    } else if(mainStep === '3.0') {
      step = '2.3';
    } else {
      step = parseFloat(mainStep)-0.1;
      step = step.toFixed(1);
    }

    onChangeMainStep(step);
  };

  const saveBtn = () => {
    let btn = <div className="btn save hover-effect" onClick={() => nextMainStep('save')}>{t('save')}</div>;
    if(mainStep === '1.1' && state.fuelService !== "") {return btn}
    if(mainStep === '1.2' && (state.gas !== "" || state.diesel !== "")) {return btn}
    if(mainStep === '2.0' && state.waterService !== "") {return btn}
    if(mainStep === '2.1' && state.water !== "") {return btn}
    if(mainStep === '2.2' && state.electService !== "") {return btn}
    if(mainStep === '2.3' && state.electric !== "") {return btn}
    if(mainStep === '3.0' && state.wasteService !== "") {return btn}
    return '';
  };

  const onChangeState = (item, value) => {
    let states = {...state};
    states[item] = value;
    setState(states);
  };

  const onChangeNumberState = (item, value) => {
    let states = {...state};
    states[item] = AddCommas(RemoveNonNumeric(value));
    setState(states);
  };

  const onChangeWasteType = (value, index) => {
    let states = {...state};
    states.wastes[index]['type'] = value;

    if(value.treatments.length === 1) {
      states.wastes[index]['treatment'] = {...value.treatments[0], disabled: true};

      let options = [...scope3WasteOptions];
      states.wastes.forEach(m => {
        options = options.filter(f => f.value !== m.type.value)
      });
      setScope3WasteOptions(options);
    } else {
      states.wastes[index]['treatment'] = '';
    }

    setState(states);
  };

  const onChangeWasteTreatment = (type, value, index) => {
    let states = {...state};
    states.wastes[index]['treatment'] = value;
    states.wastes[index]['type']['treatments'] = type.treatments.filter(f => f.id !== value.id);

    if(states.wastes[index]['type']['treatments'].length === 0) {
      let options = [...scope3WasteOptions];
      states.wastes.forEach(m => {
        options = options.filter(f => f.value !== m.type.value)
      });
      setScope3WasteOptions(options);
    }

    setState(states);
  };

  const onChangeNumberWasteState = (item, value, index) => {
    let states = {...state};
    states.wastes[index][item] = AddCommas(RemoveNonNumeric(value));
    setState(states);
  };

  const addNewWaste = () => {
    let states = {...state};
    states.wastes.push({'type': '', 'treatment': '', 'value': ''});
    setState(states);
  };

  const removeWaste = (index) => {
    let states = {...state};
    states.wastes.splice(index, 1);
    setState(states);
  };


  return (
    <div className={`createCarbonMainContainer step_${mainStep}`}>
      <div className="sideBar">
        {mainStep > '0' &&
          <div className="menu">
            <div className={`scope scope1 ${mainStep >= 1 && mainStep < 2 && 'active'}`} onClick={() => onChangeMainStep('1.0')}>
              {t('carbon_page.scope')} 1
            </div>
            <div className={`scope scope2 ${mainStep >= 2 && mainStep < 3 && 'active'}`} onClick={() => onChangeMainStep('2.0')}>
              {t('carbon_page.scope')} 2
            </div>
            <div className={`scope scope3 ${mainStep >= 3 && mainStep < 4 && 'active'}`} onClick={() => onChangeMainStep('3.0')}>
              {t('carbon_page.scope')} 3
            </div>
          </div>
        }
        {mainStep >= '0' &&
          <div className="footer">
            <div className="title">{footerMsg.find(f => f.step === mainStep)?.title}</div>
            <div className="copy">{footerMsg.find(f => f.step === mainStep)?.copy}</div>
          </div>
        }
      </div>

      <div className="steps">
        {mainStep === '0' &&
          <div className="step step0">
            <div className="title">{t('carbon_page.what_year')}</div>
            <div className="desc">
              <label>
                {t('carbon_page.what_year_note')}
              </label>
              <div style={{width: '200px'}}>
                <Select
                  isSearchable={false}
                  placeholder={t('select')}
                  value={{label: state.year, value: state.year}}
                  onChange={(event) => onChangeState('year', event.value)}
                  styles={customDropdownCO2Styles}
                  options={yearOptions}
                  className={`react-select ${state.yearError && state.year === '' && 'has-error'}`}
                />
              </div>
            </div>
          </div>
        }

        {mainStep === '1.0' &&
          <div className="step step1">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.company_vehicles')}</div>
            <div className="desc">
              <label>{t('carbon_page.company_vehicles2')}</label>

              <div className="radioInputsRow">
                <div className="radioCheck">
                  <Input
                    name="type"
                    id="companyType1"
                    type="radio"
                    checked={state.car === 'yes'}
                    value="yes"
                    onChange={event => onChangeState('car', event.target.value)}
                  />
                  <label htmlFor="companyType1"> {t('yes')} </label>
                </div>
                <div className="radioCheck">
                  <Input
                    name="type"
                    id="companyType2"
                    type="radio"
                    checked={state.car === 'no'}
                    value="no"
                    onChange={event => onChangeState('car', event.target.value)}
                  />
                  <label htmlFor="companyType2"> {t('no')} </label>
                </div>
              </div>
            </div>
            <div className="legal">
              {t('carbon_page.company_vehicles3')}
            </div>
          </div>
        }

        {mainStep === '1.1' &&
          <div className="step step_1_1">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.fuel')}</div>
            <div className="desc">
              <label>{t('carbon_page.who_service_provider')}</label>

              <div style={{width: '80%'}}>
                <Select
                  isSearchable={false}
                  placeholder={t('carbon_page.choose_service_provider')}
                  value={state.fuelService}
                  onChange={(event) => onChangeState('fuelService', event)}
                  styles={customDropdownCO2Styles}
                  options={services && services.results ? services.results.filter(f => f.type === 'fuel').map(m => {
                    return {label: m.name, value: m.name, id: m.id}
                  }) : []}
                />
              </div>
            </div>
          </div>
        }

        {mainStep === '1.2' &&
          <div className="step step_1_2">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.fuel')}</div>
            <div className="desc">
              <label>{t('carbon_page.fuel_petrol1')} <b>{t('carbon_page.fuel_petrol2')}</b> {t('carbon_page.fuel_petrol3')}</label>
              <div style={{width: '50%'}}>
                <Input
                  name="other"
                  type="text"
                  value={state.gas}
                  className="form-control"
                  onChange={event => onChangeNumberState("gas", event.target.value)}
                />
                <label>l</label>
              </div>

              <label>{t('carbon_page.fuel_diesel1')} <b>{t('carbon_page.fuel_diesel2')}</b> {t('carbon_page.fuel_diesel3')}</label>
              <div style={{width: '50%'}}>
                <Input
                  name="other"
                  type="text"
                  value={state.diesel}
                  className="form-control"
                  onChange={event => onChangeNumberState("diesel", event.target.value)}
                />
                <label>l</label>
              </div>
            </div>
            <div className="legal">
              {t('carbon_page.fuel_note')}
            </div>
          </div>
        }

        {mainStep === '2.0' &&
          <div className="step step2">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.hot_water2')}</div>
            <div className="desc">
              <label>{t('carbon_page.who_service_provider')}</label>
              <div style={{width: '80%'}}>
                <Select
                  isSearchable={false}
                  placeholder={t('carbon_page.choose_service_provider')}
                  value={state.waterService}
                  onChange={(event) => onChangeState('waterService', event)}
                  styles={customDropdownCO2Styles}
                  options={services && services.results ? services.results.filter(f => f.type === 'water').map(m => {
                    return {label: m.name, value: m.name, id: m.id}
                  }) : []}
                />
              </div>
            </div>
          </div>
        }

        {mainStep === '2.1' &&
          <div className="step step_2_1">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.hot_water2')}</div>
            <div className="desc">
              <label>{t('carbon_page.hot_water3')}</label>
              <div style={{width: '70%'}}>
                <Input
                  name="other"
                  type="text"
                  value={state.water}
                  className="form-control"
                  onChange={event => onChangeNumberState("water", event.target.value)}
                />
                <label>m3</label>
              </div>

              <div className="legal">
                {t('carbon_page.hot_water_note')}
              </div>
            </div>
          </div>
        }

        {mainStep === '2.2' &&
          <div className="step step_2_2">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.electricity2')}</div>
            <div className="desc">
              <label>{t('carbon_page.who_service_provider')}</label>
              <div style={{width: '80%'}}>
                <Select
                  isSearchable={false}
                  placeholder={t('carbon_page.choose_service_provider')}
                  value={state.electService}
                  onChange={(event) => onChangeState('electService', event)}
                  styles={customDropdownCO2Styles}
                  options={services && services.results ? services.results.filter(f => f.type === 'electricity').map(m => {
                    return {label: m.name, value: m.name, id: m.id}
                  }) : []}
                />
              </div>
            </div>
          </div>
        }

        {mainStep === '2.3' &&
          <div className="step step_2_3">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.electricity2')}</div>
            <div className="desc">
              <label>
                {t('carbon_page.electricity3')}
              </label>
              <div style={{width: '70%'}}>
                <Input
                  name="other"
                  type="text"
                  value={state.electric}
                  className="form-control"
                  onChange={event => onChangeNumberState("electric", event.target.value)}
                />
                <label>{t('carbon_page.kwh')}</label>
              </div>

            </div>
          </div>
        }

        {mainStep === '3.0' &&
          <div className="step step3">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.waste')}</div>
            <div className="desc">
              <label>{t('carbon_page.who_service_provider')}</label>
              <div style={{width: '80%'}}>
                <Select
                  isSearchable={false}
                  placeholder={t('carbon_page.choose_service_provider')}
                  value={state.wasteService}
                  onChange={(event) => onChangeState('wasteService', event)}
                  styles={customDropdownCO2Styles}
                  options={services && services.results ? services.results.filter(f => f.type === 'waste').map(m => {
                    return {label: m.name, value: m.name, id: m.id}
                  }) : []}
                />
              </div>
            </div>
          </div>
        }

        {mainStep === '3.1' &&
          <div className="step step_3_1">
            <div className="head">{t('carbon_page.information_for')} {state.year}</div>
            <div className="title">{t('carbon_page.waste')}</div>
            <div className="desc">
              <label>{t('carbon_page.waste_categories')}</label>
              {state.wastes.map((item, index) =>
                <div className="row" key={"scope3_1_waste_" + index}>
                  <div className="col-4">
                    <label htmlFor={'type'+index}>{t('carbon_page.waste_category')}</label>
                    <Select
                      id={'type'+index}
                      isSearchable={false}
                      placeholder={t('select')}
                      // value={state.wastes[index].type || {}}
                      value={state.wastes[index].type ? {...state.wastes[index].type, label: state.wastes[index].type["label_"+currentLang]} : {}}
                      onChange={(event) => onChangeWasteType(event, index)}
                      styles={customDropdownCO2Styles}
                      options={[
                        ...scope3WasteOptions && scope3WasteOptions.map((item) => {
                          return {...item, label: item["label_"+currentLang]}
                        })
                      ]}
                      className={`react-select ${state.wastesError && state.wastes[index].type === '' && 'has-error'}`}
                    />
                  </div>

                  <div className="col-3">
                    <label htmlFor={'treatment'+index}>{t('carbon_page.treatment')}</label>
                    <Select
                      id={'treatment'+index}
                      isSearchable={false}
                      placeholder={t('select')}
                      isDisabled={!!(state.wastes[index].treatment && state.wastes[index].treatment.disabled === true) }
                      onChange={(event) => onChangeWasteTreatment(state.wastes[index].type, event, index)}
                      value={state.wastes[index].treatment ? {...state.wastes[index].treatment, label: state.wastes[index].treatment["label_"+currentLang]} : {}}
                      styles={customDropdownCO2Styles}
                      // options={state.wastes[index].type ? state.wastes[index].type.treatments : []}
                      options={
                        state.wastes[index].type ? state.wastes[index].type.treatments.map((item) => {
                          return {...item, label: item["label_"+currentLang]}
                        }) : []
                      }
                      className={`react-select ${state.wastesError && state.wastes[index].treatment === '' && 'has-error'}`}
                    />
                  </div>

                  <div className="col-3">
                    <label htmlFor={'value'+index}>{t('carbon_page.amount')}</label>
                    <Input
                      id={'value'+index}
                      name="other"
                      type="text"
                      value={state.wastes[index].value}
                      onChange={event => onChangeNumberWasteState("value", event.target.value, index)}
                      className={`form-control ${state.wastesError && state.wastes[index].value === '' && 'has-error'}`}
                    />
                  </div>
                  <div className="col-1" style={{paddingTop: '30px'}}>kg</div>
                  <div className="col-1 removeWaste" style={{paddingTop: '30px'}} onClick={() => removeWaste(index)}><Cancel/></div>
                </div>
              )}
            </div>

            <div className="addNewWaste" onClick={() => addNewWaste()}>{t('carbon_page.add_category')}<AddCircle/> </div>

            <div className="legal">{t('carbon_page.waste_note')}</div>
          </div>
        }
      </div>

      <div className="btns">
        {saveBtn()}
        {mainStep > 0 && <div className="btn hover-effect" onClick={() => prevMainStep()}>{t('carbon_page.back')}</div>}
        <div className="btn hover-effect" onClick={() => nextMainStep()}>{nextBtnMsg[mainStep]}</div>
      </div>
    </div>
  )
};

export default CreateCarbonMainPage