import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getStories = () => async dispatch => {
  dispatch({type: actionTypes.GET_STORIES_START});
  try {
    const res = await axios.get('/company_story?admin=true');
    dispatch({type: actionTypes.GET_STORIES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_STORIES_FAIL});
  }
};

export const getStoriesByCompany = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_STORIES_COMPANY_START});
  try {
    const res = await axios.get('/company_story/by_company/'+companyId);
    dispatch({type: actionTypes.GET_STORIES_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_STORIES_COMPANY_FAIL});
  }
};

export const createUpdateStories = ({update, data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_STORIES_START : actionTypes.CREATE_STORIES_START});
  try {
    const res = await axios({
      url: update ? '/company_story/'+ data.id : '/company_story',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: update ? actionTypes.UPDATE_STORIES_SUCCESS : actionTypes.CREATE_STORIES_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Saga vistuð', style:'success'});
    dispatch(getStoriesByCompany(data.company_id));
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_STORIES_FAIL : actionTypes.CREATE_STORIES_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};


export const deleteStory = (storyId) => async dispatch => {
  dispatch({type: actionTypes.DELETE_STORIES_START});
  try {
    const res = await axios.delete('/company_story/' + storyId);
    dispatch(getStoriesByCompany(res.data.results.company_id));
    dispatch({type: actionTypes.DELETE_STORIES_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Sögu eytt', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_STORIES_FAIL});
  }
};

export const updateCompanyStoryStatus = (id, status) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_STORY_STATUS_START});
  try {
    const res = await axios({
      url: '/company_story/update_status/'+id,
      method: 'PUT',
      data: {
        'approved' : status
      }
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Breytingar vistaðar', style:'success'});
    dispatch({type: actionTypes.UPDATE_STORY_STATUS_SUCCESS, data: res.data});
    dispatch(getStories());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_STORY_STATUS_FAIL, data: errorMessage});
  }
};