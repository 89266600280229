import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";
import IrrelevantSwitch from "../irrelevantSwitch";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep212 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(0);
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(first>=0 && second>=0) {
      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": `Árið 2024 voru ${first} starfsfólks hjá fyrirtækinu í hlutastarfi og ${second} starfsfólks í verktöku og/eða ráðgjöf.`,
          "comment_en": `In 2024, ${first} of employees at the company were part-time and ${second} of employees were in contracting and/or consulting.`,
          "first" : first,
          "second" : second,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <div className="customGreenStep210">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
            :
            desc
        }
      </div>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      <div className="customGreenStep">
        <p className="categoryTextColor">{t('pluses_page.percentage_of_part-time_employees')}</p>

        <div className="row">
          <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-2 inputCol">
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={first}
              onChange={(event) => {
                event.target.value <= 100 ? setFirst(event.target.value) : setFirst(100)
              }}
              invalid={error && first.length === 0}
            />
            <span className="categoryTextColor">%</span>
          </div>
        </div>

        <br/>

        <p className="categoryTextColor">{t('pluses_page.percentage_contracting')}</p>
        <div className="row">
          <div className="col-sm-10 inputCol">
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={second}
              onChange={(event) => {
                event.target.value <= 100 ? setSecond(event.target.value) : setSecond(100)
              }}
              invalid={error && second.length === 0}
            />
            <span className="categoryTextColor">%</span>
          </div>
          <div className="col-2 save">
            <div className="saveBtn categoryBgColor" onClick={() => handleCheck()}>
              {t('save')}
            </div>
          </div>
        </div>

      </div>

    </div>
  )
};

export default GreenStep212