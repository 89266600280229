import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import ReactPlayer from "react-player";
import {Input} from "reactstrap";
import Swal from "sweetalert";

import {createUpdateStories, deleteStory, getStoriesByCompany} from "../../store/actions/story";
import PictureUpload from "../../components/Common/CustomUpload/PictureUpload";
import {LoaderSpinner} from "../../components/Common/Loader";
import previewImg from "../../assets/images/preview_img.jpg";
import {deleteCompanyEnvironmental} from "../../store/actions/companyEnvironmental";
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";

const Stories = () => {
  const dispatch = useDispatch();
  const {storiesCompany, loadingStoriesCompany, loadingSave} = useSelector( state => state.story );

  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData ? userData.companyId : 0;

  const [errorStatus, setErrorStatus] = useState(false);
  const emptyData = {
    "id": 0,
    "title": '',
    "preview": '',
    "link": '',
    "approved": false,
    "company_id": companyId,
  };
  const [newItemData, setNewItemData] = useState(emptyData);
  const [itemData, setItemData] = useState(emptyData);

  useEffect(() => {
    dispatch(getStoriesByCompany(companyId));
  }, []);

  const toggleCard = (index) => {
    let data = storiesCompany.results[index];
    setItemData({
      "id": data.id,
      "company_id": data.company_id,
      "title": data.title || '',
      "preview": data.preview || '',
      "link": data.link || '',
      "approved": false,
    });
  };

  const onChange = (value, state, type) => {
    let item = (type === 'new') ? {...newItemData} : {...itemData};
    item[state] = value;
    (type === 'new') ? setNewItemData(item) : setItemData(item);
  };

  const onUpload = (data, type) => {
    let item = (type === 'new') ? {...newItemData} : {...itemData};
    item.preview = data;
    (type === 'new') ? setNewItemData(item) : setItemData(item);
  };

  const handleSave = (type) => {
    let data = (type === 'new') ? newItemData : itemData;
    if(data.title.length === 0 || data.link.length === 0) {
      setErrorStatus(true);
      Swal({text:"Vinsamlega fylltu út í alla nauðsynlega textareiti", icon: "warning", timer: 4000, buttons: false});
    } else {
      dispatch(createUpdateStories({update: type !== 'new', data: data }));
      (type === 'new') ? setNewItemData(emptyData) : setItemData(emptyData);
    }
  };

  if (loadingStoriesCompany) return (<LoaderSpinner/>);

  return (
    <div className="row stories">
      <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">

        <div className="row">

          {(!storiesCompany.results || (storiesCompany.results && storiesCompany.results.length < 6)) &&
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
              <div className="card">
                <div className="card-body">
                  <div className="cardFront">
                    <div className="storiesHeader">{t('stories_page.add_new')}</div>

                    <div>
                      <label className="form-label">{t('stories_page.title')} <small>({t('required')})</small></label>
                      <Input
                        name="link"
                        type="text"
                        value={newItemData.title}
                        className="form-control"
                        onChange={event => onChange(event.target.value, 'title', 'new')}
                        invalid={errorStatus && !newItemData.title.length}
                      />
                    </div>

                    <div>
                      <label className="form-label">{t('stories_page.link')} <small>({t('required')})</small></label>
                      <Input
                        name="link"
                        type="text"
                        value={newItemData.link}
                        className="form-control"
                        onChange={event => onChange(event.target.value, 'link', 'new')}
                        invalid={errorStatus && !newItemData.link.length}
                      />
                    </div>

                    <PictureUpload
                      imgHolder={newItemData.preview || previewImg}
                      msg={t('stories_page.preview')}
                      onUpload={(data) => onUpload(data, 'new')}
                    />

                    <div disabled={loadingSave} className="btn btn-primary waves-effect videoBtn" onClick={() => handleSave('new')} >
                      {loadingSave ? t('stories_page.saving') : t('stories_page.save') }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {!storiesCompany.results  ? '' : storiesCompany.results.map((item, index) => (
            <div
              className={`col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 `}
              key={'video_card' + index}
            >
              <div className={`card ${itemData.id === item.id ? 'active' : ''}`}>
                <div className="card-body">

                  <div className="cardFront">
                    <div className="videoTitle">{item.title}</div>
                    <div className={`videoStatus ${item.approved}`}>
                      {t('company_story_page.'+item.approved)}
                    </div>

                    <ReactPlayer
                      className="videoPlayer"
                      url={item.link}
                      light={item.preview}
                      controls
                    />

                    <div className="footerBtn">
                      <DeleteButton  msg={t('stories_page.delete')} action={() => dispatch(deleteStory(item.id))}/>

                      <div className="btn btn-primary waves-effect videoBtn" onClick={() => toggleCard(index)}>
                        {t('stories_page.edit')}
                      </div>
                    </div>
                  </div>

                  <div className="cardBack">
                    <div className="storiesHeader">{t('stories_page.edit_story')}</div>

                    <div>
                      <label className="form-label">{t('stories_page.title')} <small>({t('required')})</small></label>
                      <Input
                        name="link"
                        type="text"
                        value={itemData.title}
                        className="form-control"
                        onChange={event => onChange(event.target.value, 'title', 'old')}
                        invalid={errorStatus && !itemData.title.length}
                      />
                    </div>

                    <div>
                      <label className="form-label">{t('stories_page.link')} <small>({t('required')})</small></label>
                      <Input
                        name="link"
                        type="text"
                        value={itemData.link}
                        className="form-control"
                        onChange={event => onChange(event.target.value, 'link', 'old')}
                        invalid={errorStatus && !itemData.link.length}
                      />
                    </div>

                    <PictureUpload
                      imgHolder={itemData.preview || previewImg}
                      msg={t('stories_page.preview')}
                      onUpload={(data) => onUpload(data, 'old')}
                    />

                    <div className="footerBtn">
                      <div disabled={loadingSave} className="btn btn-primary waves-effect videoBtn" onClick={() => setItemData(emptyData)}>
                        {t('stories_page.back')}
                      </div>

                      <div disabled={loadingSave} className="btn btn-primary waves-effect videoBtn" onClick={() => handleSave('old')}>
                        {loadingSave ? t('stories_page.saving') : t('stories_page.save')}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>

    </div>
</div>
)
};
export default Stories;