import React, {useEffect} from "react"
import {useDispatch} from "react-redux"
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next";

import Header from "./Header"
import IdleTimer from "../Common/IdleTimer/IdleTimer";
import Snackbar from "../Common/Snackbar";
import {logout} from "../../store/actions/auth";
import Pages404 from "../../pages/pages-404";
import Bugsnag from "../../helpers/bugsnag";
// import {initializeTagManager} from "../../helpers/gtm";
import { LiveChatWidget } from "@livechat/widget-react";

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value);
  return true
};

const manageBodyClass = (cssClass, action = "toggle") => {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break
  }
  return true
};

const Layout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  if(!localStorage.getItem('p_user')) {
    localStorage.clear();
    window.location = '/login';
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    document.title = `${t(location.pathname === '/' ? 'dashboard' : location.pathname.slice(1))} | Laufið`;

    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-sidebar-size");
    manageBodyClass("vertical-collpsed", "remove");
    manageBodyClass( "sidebar-enable", "remove");
    changeBodyAttribute("data-topbar", "light");
    changeBodyAttribute("data-layout", "horizontal");
    changeBodyAttribute( "data-layout-size", "fluid");
    changeBodyAttribute( "data-layout-scrollable", true);
  });


  React.useEffect(() => {
    // initializeTagManager();

    const timer = new IdleTimer({
      timeout: 3600, //expire after 10 seconds
      onTimeout: () => {
        dispatch(logout(navigate));
      },
      onExpired: () => {
        dispatch(logout(navigate));
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  function handleNewEvent(event) {
    // console.log("LiveChatWidget.onNewEvent", event);
  }

  return (
    <ErrorBoundary FallbackComponent={Pages404}>
      <div className="container-fluid">
        <div id="layout-wrapper">

          <header id="page-topbar">
            <Header/>
          </header>

          <div className="main-content">
            <Outlet/>
            <Snackbar />
            <LiveChatWidget
              license="14551761"
              visibility="minimized"
              onNewEvent={handleNewEvent}
            />
          </div>

        </div>
      </div>
    </ErrorBoundary>
  )

};

export default Layout