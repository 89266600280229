import React from "react"
import {Routes, Route} from 'react-router-dom';
import {AuthRoute, PrivateRoute} from './helpers/privateRoute'

// layouts Format
// import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"

import Login from "./pages/Authentication/Login"
import Registration from "./pages/Authentication/Registration"
import ResetPassword from "./pages/Authentication/ResetPassword"
import VerifyEmail from "./pages/Authentication/VerifyEmail"
import LandingPage from "./pages/Authentication/LandingPage"
import Pages404 from "./pages/pages-404"

import Dashboard from "./pages/Dashboard/index"
import GreenSteps from "./pages/Green_steps/index"
import Announcements from "./pages/Announcements/index"
import Carbon from "./pages/Carbon/index"
import Education from "./pages/Education/index"

import Companies from "./pages/Companies/index"
import CompanyCategories from "./pages/Company_categories/index"
import CompanyEnvironmental from "./pages/Company_environmental/index"
import CompanyStories from "./pages/Company_stories/index"

import Users from "./pages/Users/index"
import UserProfile from "./pages/User_profile/index"
import GreenStepList from "./pages/Gree_steps_list/index"
import ActionPlan from "./pages/ActionPlan/index"
import CertificatesCompany from "./pages/Certificates_company/index"
import CertificatesList from "./pages/Certificates_list/index"
import CarbonList from "./pages/Carbon_list/index"
import AnnouncementsList from "./pages/Announcements_list/index"
import ActivityLog from "./pages/ActivityLog/index"

import Leaf_way from "./pages/Leaf_way/index"
import Permission from "./pages/Permission/index"
import Role from "./pages/Role/index"


const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<AuthRoute component={Login} />} />
      <Route path="/registration" element={<AuthRoute component={Registration} />} />
      <Route path="/reset_password" element={<AuthRoute component={ResetPassword} />} />
      <Route path="/verify_email" element={<AuthRoute component={VerifyEmail} />} />
      <Route path="/landing_page" element={<AuthRoute component={LandingPage} />} />
      <Route element={<HorizontalLayout />}>

        <Route path="/" element={<PrivateRoute permissions={"dashboard_page"} component={Dashboard} />} />
        <Route path="/dashboard" element={<PrivateRoute permissions={"dashboard_page"} component={Dashboard} />} />
        <Route path="/profile" element={<PrivateRoute permissions={"profile_page"} component={UserProfile} />} />
        <Route path="/adgerdir" element={<PrivateRoute permissions={"pluses_page"} component={GreenSteps} />} />
        <Route path="/action-plan" element={<PrivateRoute permissions={"plan_page"} component={ActionPlan} />} />
        <Route path="/leaf_way" element={<PrivateRoute permissions={"book_page"} component={Leaf_way} />} />
        <Route path="/announcements" element={<PrivateRoute permissions={"pluses_page"} component={Announcements} />} />
        <Route path="/carbon" element={<PrivateRoute permissions={"pluses_page"} component={Carbon} />} />
        <Route path="/education" element={<PrivateRoute permissions={"pluses_page"} component={Education} />} />

        <Route path="/users" element={<PrivateRoute permissions={"admin_users_page"} component={Users} />} />
        <Route path="/green-steps-list" element={<PrivateRoute permissions={"admin_pluses_page"} component={GreenStepList} />} />
        <Route path="/company-history" element={<PrivateRoute permissions={"admin_pluses_page"} component={ActivityLog} />} />
        <Route path="/certificates-list" element={<PrivateRoute permissions={"admin_certificates_page"} component={CertificatesList} />} />
        <Route path="/certificates-company" element={<PrivateRoute permissions={"admin_certificates_company_page"} component={CertificatesCompany} />} />
        <Route path="/companies" element={<PrivateRoute permissions={"admin_companies_page"} component={Companies} />} />
        <Route path="/company-categories" element={<PrivateRoute permissions={"admin_company_categories_page"} component={CompanyCategories} />} />
        <Route path="/company-environmental" element={<PrivateRoute permissions={"admin_company_environmental_page"} component={CompanyEnvironmental} />} />
        <Route path="/company-stories" element={<PrivateRoute permissions={"admin_company_environmental_page"} component={CompanyStories} />} />
        <Route path="/carbon-list" element={<PrivateRoute permissions={"admin_pluses_page"} component={CarbonList} />} />
        <Route path="/announcements-list" element={<PrivateRoute permissions={"admin_pluses_page"} component={AnnouncementsList} />} />
        <Route path="/permissions" element={<PrivateRoute permissions={"admin_permissions_page"} component={Permission} />} />
        <Route path="/roles" element={<PrivateRoute permissions={"admin_roles_page"} component={Role} />} />

      </Route>
      <Route path="*" element={<Pages404/>} />
    </Routes>
  )
};

export default App